<template>
    <div>

        <div class="col-md-12 col-lg-12">
            <h3 class=" font__size-28 line__height-38 mb-30 text-center">
               {{ $t('race_result.select') }}
            </h3>


            <hr>
            <div class="row mt-40">
                <div class="col-md-5 mt-1">
                    <select class="browser-default  form-control" v-model="raceId">
                        <option value="null" selected>{{ $t('race_result.select_menu') }}</option>
                        <option v-for="race in shown_races" v-bind:key="race.id" :value="race.id">
                            {{ race.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4 mt-1">
                    <input class="form-control" id="racerNumber" v-model="racerId"
                           :placeholder="$t('race_result.runner_id')">
                </div>

                <div class="col-md-3 d-flex justify-content-center">
                    <button class="mt-20 btn btn-inside-out btn-md
                            btn-icon-abs

                            border-radius-25 font__family-open-sans
                            font__weight-bold btn-min-width-200" data-brk-library="component__button"
                            @click="getResult()">
                        <span class="before">{{ $t('race_result.results') }}</span><span class="text">{{ $t('race_result.results') }}</span>
                        <span class="after">{{ $t('race_result.results') }}</span>
                    </button>
                </div>
            </div>


            <span class="text-danger font__size-20 align-middle" v-if="not_found">{{ not_found }}</span>
        </div>


        <div class="col-md-12 mt-40">

            <table class="table justify-content-center" v-if="race">
                <thead class="head-bg">
                <tr>
                   <th class="text-white" scope="col">{{ $t('race_result.name') }}</th>
                    <!-- <th class="text-white" scope="col">{{ $t('race_result.last_name') }}</th> -->
                    <!-- <th class="text-white" scope="col">{{ $t('race_result.gender') }}</th> -->
                    <th class="text-white" scope="col">{{ $t('race_result.start_num') }}</th>
                    <th class="text-white" scope="col">{{ $t('race_result.status') }}</th>
                    <th class="text-white" scope="col">{{ $t('race_result.town') }}</th>
                    <th class="text-white" scope="col">{{ $t('race_result.club') }}</th>
                    <th class="text-white" scope="col">{{ $t('race_result.rank') }}</th>
                    <th class="text-white" scope="col">{{ $t('race_result.time') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{{ race.first_name }}</td>
                    <!-- <td>{{ race.first_name }}</td> -->
                    <td>{{ race.pivot.start_nr }}</td>
                    <td>{{ race.pivot.status }}</td>
                    <td>{{ race.city }}</td>
                    <td>{{ race.club }}</td>
                    <td>{{ race.pivot.apsolutni_poredak }}</td>
                    <td>{{ race.pivot.cilj_vrijeme }}</td>

                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.setLang()
            this.getRaces()
        },
        data() {
            return {
                proxyurl: "https://cors-anywhere.herokuapp.com/",
                shown_races: '',
                raceId: null,
                racerId: null,
                race: null,
                not_found: '',
                lang: '',
                error: false
            }
        },

        methods: {
            setLang() {
                this.lang = window.default_locale
                this.$i18n.locale = window.default_locale
            },
            getRaces() {
                axios.post("/get-races", {locale: this.lang})
                    .then(response => {
                        this.shown_races = response.data;
                        this.shown_races.sort((a,b) => (a.name > b.name) ? 1 : -1)
                    })
                    .catch(error => {
                        this.error = true;
                    })
            },

            getResult() {
                axios.get("/api/v1/races/results", {params:
                    {
                        race: this.raceId,
                        racer: this.racerId
                    }
                })
                    .then(response => {
                        if (response.data) {
                            this.race = response.data;
                            this.not_found = null
                        } else {
                            this.not_found = this.$t('race_result.result_error')
                        }
                    })
                    .catch(error => {
                        this.error = true;
                    });
            },
        }
    }
</script>

<style>

    .head-bg {
        background: #68ADFF;
    }
</style>
