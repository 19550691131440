<template>

<div>
    <div v-show="!registrationSuccessful">
            <h2 class="font__family-montserrat font__weight-bold text-center">{{$t('volunteers.aplication_title3')}}</h2>
            <h4 class="font__family-montserrat font__weight-light text-center mt-20 font-size-39 mb-5">{{$t('volunteers.aplication_title4')}}</h4>

        <h4 class="title__divider title__divider--line font__size-21 font__weight-bold font__family-montserrat line__height-24 text-left"
            data-brk-library="component__dividers">
            <span class="title__divider__wrapper"><span class="text-primary">{{$t('volunteers.aplication_title1')}}</span> {{$t('volunteers.aplication_title2')}}</span>
            <span class="line main-gradient-linear-2"></span>
        </h4>
        <div class="row mt-40 mb-3">
            <div class="col-md-6">
                <input type="text"
                    :placeholder="$t('volunteers.first_name')"
                    v-model="user.first_name"
                    class="form-control"
                    :class="{ 'is-invalid': errors['user.first_name'] }"
                    required>
                    <span class="invalid-feedback" role="alert">
                    <strong v-for="error in errors['user.first_name']"> {{ error }}</strong></span>

            </div>
            <div class="col-md-6">
                <input type="text"
                    :placeholder="$t('volunteers.last_name')"
                    v-model="user.last_name"
                    class="form-control"
                    :class="{ 'is-invalid': errors['user.last_name'] }"
                    required>
                    <span class="invalid-feedback" role="alert" >
                    <strong v-for="error in errors['user.last_name']"> {{ error }}</strong></span>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <input type="text"
                        :placeholder="$t('volunteers.mail')"
                        v-model="user.email"
                        class="form-control"
                        :class="{ 'is-invalid': errors['user.email'] }"
                        required>
                    <span class="invalid-feedback" role="alert">
                    <strong v-for="error in errors['user.email']"> {{ error }}</strong></span>
            </div>

            <div class="col-md-6">
                <input type="text"
                        :placeholder="$t('volunteers.phone')"
                        v-model="user.mobile_phone"
                        :class="{ 'is-invalid': errors['user.mobile_phone'] }"
                        class="form-control"
                        required>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.mobile_phone']"> {{ error }}</strong></span>
            </div>
    </div>

          
       

        <div class="row mt-3">
            <div class="col-md-6">
                <datetime v-model="user.date_of_birth"
                        :class="{ 'is-invalid': errors['user.date_of_birth'] }"
                        input-class="form-control"
                        format="dd.MM.yyyy"
                        :placeholder="$t('volunteers.date_of_birth')"
                        required>
                </datetime>
                <div v-show="errors['user.date_of_birth']" class="invalid">
                    <strong v-for="error in  errors['user.date_of_birth']"> {{ error }}</strong>
                </div>
            </div>

            <div class="col-md-6">
                <v-select v-model="user.status"
                        class="form-control p-1"
                        :class="{ 'is-invalid': errors['user.status'] }"
                        :placeholder="$t('volunteers.status')"
                        :options= "[$t('volunteers.pupil'),$t('volunteers.student'),$t('volunteers.unemployed'),$t('volunteers.employed'),$t('volunteers.retiree')]"
                        required>
                </v-select>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.status']"> {{ error }}</strong></span>
            </div>

            </div>
            <div class="row mt-3">

            <div class="col-md-6">
                <input type="text"
                    :placeholder="$t('volunteers.school')"
                    v-model="user.school_name"
                    class="form-control"
                    :class="{ 'is-invalid': errors['user.school_name'] }"
                    required>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.school_name']"> {{ error }}</strong></span>
            </div>

            <div class="col-md-6">
                <input type="text"
                        :placeholder="$t('volunteers.grade')"
                        v-model="user.grade"
                        :class="{ 'is-invalid': errors['user.grade'] }"
                        class="form-control"
                        required>
                    <span class="invalid-feedback" role="alert">
                    <strong v-for="error in errors['user.grade']"> {{ error }}</strong></span>
            </div>
            
        </div>

       

        <div class="row mt-3">

            <div class="col-md-6">
                <v-select v-model="user.shirt"
                            class="form-control p-1"
                            :placeholder="$t('volunteers.shirt_size')"
                            :class="{ 'is-invalid': errors['user.shirt'] }"
                            :options="['XS','S','M','L','XL']"
                            required>
                </v-select>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.shirt']"> {{ error }}</strong></span>
            </div>


            <div class="col-md-6">
                <v-select v-model="user.sektor"
                            class="form-control p-1"
                            :placeholder="$t('volunteers.volunteer')"
                            :class="{ 'is-invalid': errors['user.sektor'] }"
                            :options="['/',$t('volunteers.zone'), $t('volunteers.wardrobe'),$t('volunteers.expo'),$t('volunteers.technical'),$t('volunteers.logistics'),$t('volunteers.station'),$t('volunteers.walls')]"
                            required>
                </v-select>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.sektor']"> {{ error }}</strong></span>
            </div>

          
            
        </div>
        <div class="row">
            <div class="col-md-12  mt-3">

                <textarea v-model="user.note"
                            class="form-control p-1"
                            :placeholder="$t('volunteers.note_label')"
                            :class="{ 'is-invalid': errors['user.note'] }"
                            >
                </textarea>
                <span class="invalid-feedback" role="alert">
                <strong v-for="error in errors['user.sektor']"> {{ error }}</strong></span>

            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <p class="font__family-open-sans text-muted">{{$t('volunteers.bonus')}}</p>
            </div>
        </div>

        <div class="row mt-3">
                <input type="checkbox" class="mt-15 ml-20" name="privacy" v-model="privacy" id="privacy">
                <label class="ml-10 mt-10" for="privacy" v-html="$t('volunteers.gdpr')">
                </label>
        </div>

        <span class="text-danger font__size-12" role="alert">
        <strong v-for="error in errors['privacy']"> {{ error }}</strong></span>

        <div class="mt-30">
                <button
                class="btn btn-inside-out btn-lg btn-icon border-radius-25 font__family-open-sans font__weight-bold"
                data-brk-library="component__button"
                @click="register()"
        >
            <span class="before">{{ $t('volunteers.register') }}</span>
            <span class="text">{{ $t('volunteers.register') }}</span>
            <span class="after">{{ $t('volunteers.register') }}</span>
        </button>
        </div>
    </div>

    <div v-show="registrationSuccessful">
            <div class="col-12 col-lg-12">
                    <div>
                        <h2 class="font__family-montserrat font__weight-bold text-center
                font__size-38 line__height-60 mt-10" data-brk-library="component__title">
                             {{ $t('volunteers.thanks1') }}<br>
                            <span class="text-primary font__size-28">{{ $t('volunteers.thanks2') }}</span>
                        </h2>

                        <div class="text-center mt-30 mb-30">
                            <a href="/" class="btn btn-inside-out btn-lg btn-icon-abs border-radius-25 mt-30 font__family-open-sans font__weight-bold btn-min-width-200 brk-library-rendered rendered" data-brk-library="component__button">
                                <span class="before">{{ $t('volunteers.back') }}</span>
                                <span class="text">{{ $t('volunteers.home') }}</span>
                                <span class="after">{{ $t('volunteers.back') }}</span>
                            </a>
                        </div>
                    </div>
                </div>
    </div>
</div>
</template>

<script>
    export default {
        mounted(){
            this.setlang()
        },
        data(){
            return {
                lang: '',
                user: {
                    first_name: "",
                    last_name: "",
                    date_of_birth: "",
                    status: "",
                    school_name: "",
                    shirt: "",
                    email: "",
                    note: "",
                    mobile_phone: "",
                    sektor: "",
                    grade: ""
                },
                privacy: false,
                registrationSuccessful: false,
                errors: {}
            }
        },

        methods: {
            setlang() {
                this.$i18n.locale = window.default_locale
                this.lang = window.default_locale
            },

            register() {

                let loader = this.$loading.show();

                this.errors = {}
                axios.post('/api/v1/volunteers', {user: this.user, privacy: this.privacy, locale: this.lang})
                    .then((response) => {
                         loader.hide();
                        dataLayer.push({
                            'event': 'VolunteerRegistred',
                            'category': 'VolunteerRegistred',
                            'language': this.lang,
                        })


                        this.registrationSuccessful = true;
                    }, (error) => {
                         loader.hide();
                        this.errors = error.response.data.errors
                    });
                }
            }
        }
</script>

<style>

</style>
