<template>
    <div class="col-md-7">
        <div v-show="!success">
            <div class="container">
                <div class="cfa__wrapper cfa__email  maxw-770 text-center">
                    <h4
                        class="font__family-montserrat font__size-28 font__weight-bold text-uppercase text-white"
                    >
                        {{ $t("newsletter.title") }}
                    </h4>
                    <p
                        class="font__family-open-sans font__size-22 font__weight-light text-white"
                        style="color: #AFB4EC;"
                    >
                        {{ $t("newsletter.subtitle") }}
                    </p>
                    <form
                        action="#"
                        class="subscr__form brk-subscribe-mail"
                        @submit.prevent="onSubmit"
                    >
                        <input
                            autocomplete="off"
                            name="email"
                            class="form-control"
                            :placeholder="$t('newsletter.placeholder')"
                            v-model="email"
                        />
                        <div>
                            <button
                                type="submit"
                                class="btn-subscr fa fa-paper-plane"
                                @click="signUpForNewsletter"
                            ></button>
                        </div>
                    </form>

                    <span
                        class="text-white font__size-18 underlineError"
                        role="alert"
                    >
                        <strong v-for="error in errors['email']">
                            {{ error }}</strong
                        ></span
                    >

                    <div class="mt--20">
                        <input
                            type="checkbox"
                            class="mt-15 ml-20"
                            name="privacy"
                            v-model="privacy"
                            id="privacy"
                        />
                        <label
                            class="ml-10 mt-10 text-white"
                            for="privacy"
                            v-html="$t('newsletter.gdpr')"
                        >
                        </label>
                    </div>

                    <span
                        class="text-white font__size-14 underlineError"
                        role="alert"
                    >
                        <strong v-for="error in errors['privacy']">
                            {{ error }}</strong
                        ></span
                    >
                </div>
            </div>
        </div>

        <div v-show="success">
            <div class="container">
                <div class="cfa__wrapper cfa__email  maxw-770 text-center">
                    <h4
                        class="font__family-montserrat font__size-28 font__weight-bold text-uppercase text-white"
                    >
                        {{ $t("newsletter.thanks") }}
                    </h4>
                    <p
                        class="font__family-open-sans font__size-22 font__weight-light text-white"
                        style="color: #AFB4EC;"
                    >
                        <i class="far fa-grin fa-5x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Toasted from "vue-toasted";
Vue.use(Toasted, {
    position: "bottom-left",
    // position: 'top-center',
    fullWidth: true,
    theme: "outline"
    // fitToScreen: true,
});

export default {
    mounted() {
        this.setlang();

        //  this.$toasted.show(
        //     '<h5 class="text-danger font__weight-bold">' +
        //         this.$t("notification.title") +
        //         "</h5> " +
        //         '<span class="desc">' +
        //         this.$t("notification.description") +
        //         "</span>" +
        //         '<h5 class="font__weight-bold text-primary">' +
        //         '<a href="' +
        //         this.$t("notification.url2") +
        //         '" target="_blank">' +
        //         this.$t("notification.read_more") +
        //         "</a>" +
        //         "</h5>"
        // );
    },
    data() {
        return {
            lang: "",
            email: "",
            privacy: false,
            success: false,
            errors: {}
        };
    },

    methods: {
        setlang() {
            this.$i18n.locale = window.default_locale;
            this.lang = window.default_locale;
        },

        signUpForNewsletter() {
            this.errors = {};
            axios
                .post("/api/v1/newsletter", {
                    email: this.email,
                    privacy: this.privacy,
                    locale: this.lang
                })
                .then(
                    response => {
                        dataLayer.push({
                            event: "NewsletterSubscriber",
                            category: "NewsletterMailchimpSubbscription",
                            language: this.lang
                        });

                        this.success = true;
                    },
                    error => {
                        this.errors = error.response.data.errors;
                    }
                );
        }
    }
};
</script>

<style>
.underlineError {
    text-decoration: underline;
    -webkit-text-decoration-color: red; /* safari still uses vendor prefix */
    text-decoration-color: red;
}

.toasted-container .toasted {
    height: 60px !important;
}

div.toasted.outline {
    border: 3px solid #008dd2 !important;
}

.desc {
    font-size: 18px;
}
</style>
