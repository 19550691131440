/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import VueI18n from 'vue-i18n'

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

Vue.use(VueI18n)


Vue.mixin({
    methods: {
        route: (name, params, absolute) => route(name, params, absolute, Ziggy),
    }
});


Vue.component('race-results', require('./components/RaceResultCheckComponent').default);
Vue.component('volunteers', require('./components/VolunteersRegistrationComponent.vue').default);
Vue.component('newsletter', require('./components/NewsletterComponent.vue').default);


const messages = {
    en: {

        notification: {
           title: 'IMPORTANT UPDATE:',
            description: 'Runners lists available, important update regarding COVID certificates',
            read_more: 'READ MORE...',
            url: '/news/runners-lists-available-important-covid-19-update',
             url2: '/news/runners-lists-available-important-covid-19-update'
        },

        newsletter: {
            title: 'DON’T MISS OUT ON ANYTHING!',
            subtitle: 'Sign up for our newsletter today!',
            placeholder: 'Your e-mail',
            gdpr: 'I accept and agree with <a href="/privacy-policy" target="_blank"> Privacy Policy</a>',
            thanks: 'THANK YOU FOR SIGNING UP!'
        },

        volunteers: {
            first_name: 'Name',
            last_name: 'Surname',
            date_of_birth: 'Date of birth',
            status: 'Status',
            school: 'School or university name',
            mail: 'E-mail',
            phone: 'Mobile phone',
            shirt_size: 'T-shirt size',
            volunteer: 'Already volunteered? Name your sector!',
            register: 'Register Now',
            zone: 'Start/finish zone',
            wardrobe: 'Wardrobe',
            expo: 'Expo',
            technical: 'Technical production',
            logistics: 'Logistics',
            station: 'Water stations',
            walls: 'City walls',
            pupil: 'Pupil',
            student: 'Student',
            unemployed: 'Unemployed',
            employed: 'Employed',
            retiree: 'Retiree',
            gdpr: 'I accept and agree with <a href="/privacy-policy" target="_blank"> Privacy Policy</a>',
            thanks1: 'Thank you for registering!',
            thanks2: 'You will receive an e-mail with all the important information shortly.',
            back: 'Back',
            home: 'Home',
            aplication_title1: 'VOLUNTEERS',
            aplication_title2: 'APPLICATION FORM',
            aplication_title3: 'Looking for an amazing volunteer opportunity?',
            aplication_title4: 'Fill out this form and become a Du Motion volunteer!',
            bonus: 'Each volunteer receives an official volunteer t-shirt, a volunteer diploma, a volunteer card with discounts from our partners and refreshment.'
        },


        eval: {
            product_deleted: 'Deleted from cart',
            product_added: 'Added to cart',
            all_fields: 'Fill all required fields!',
            already_selected: 'You have already selected package for race',

        },

        race_result: {
            select: 'Here you can find all of your hard earned results.',
            select_menu: 'Select your race',
            runner_id: 'Bib number',
            results: 'Get Results',
            result_error: 'Nothing found, please try again!',
            name: 'Name',
            last_name: 'Last Name',
            gender: 'Gender',
            start_num: 'Starting number',
            status: 'Status',
            town: 'Town',
            club: 'Club',
            rank: 'Rank',
            time: 'Time'
        },


    },
    hr: {

        notification: {
              title: 'OBAVIJEST:',
            description: 'Dostupne liste trkača, važna obavijest o COVID potvrdama',
            read_more: 'SAZNAJ VIŠE...',
            url: '/hr/novosti/dostupne-liste-trkaca-vazna-novost-o-covid-potvrdama',
            url2: '/hr/novosti/dostupne-liste-trkaca-vazna-novost-o-covid-potvrdama',
        },

        newsletter: {
            title: 'Neka ti ništa ne promakne!',
            subtitle: 'Pretplati se već danas na naš newsletter!',
            placeholder: 'Tvoj e-mail',
            gdpr: 'Slažem se i prihvaćam <a href="/hr/pravila-privatnosti" target="_blank">Pravila Privatnosti</a>',
            thanks: 'HVALA NA PRIJAVI!'
        },

        eval: {
            already_selected: 'Već ste odabrali paket za utrku',
        },

        volunteers: {
            first_name: 'Ime',
            last_name: 'Prezime',
            date_of_birth: 'Datum rođenja',
            status: 'Trenutni status',
            school: 'Ime škole ili fakulteta',
            mail: 'E-mail',
            phone: 'Broj mobitela',
            shirt_size: 'Veličina majce',
            volunteer: 'Već si volontirao/la? Navedi u kojem sektoru!',
            register: 'Završi prijavu',
            zone: 'Start/cilj',
            wardrobe: 'Garderoba',
            expo: 'Expo',
            technical: 'Montaža',
            logistics: 'Logistika',
            station: 'Okrijepne stanice',
            walls: 'Zidine',
            pupil: 'Učenik',
            student: 'Student',
            unemployed: 'Ne zaposlen',
            employed: 'Zaposlen',
            retiree: 'Umirovljenik',
            gdpr: 'Slažem se i prihvaćam <a href="/hr/pravila-privatnosti" target="_blank">Pravila Privatnosti</a>',
            thanks1: 'Hvala na prijavi!',
            thanks2: 'Ubrzo će na tvoju adresu stići e-mail sa svim važnim informacijama. ',
            back: 'Nazad',
            home: 'Naslovna',
            aplication_title1: 'PRIJAVNICA',
            aplication_title2: 'ZA VOLONTERE',
            aplication_title3: 'Tražiš izvrsnu priliku za volontiranje?',
            aplication_title4: 'Ispuni ovu prijavnicu i postani volonter Du Motiona!',
            bonus: 'Svaki volonter dobija službenu volontersku majicu, volontersku kapu, volontersku diplomu, volontersku karticu s popustima kod naših partnera i okrepu.'
        },

        race_result: {
            select: 'Ovdje možete pronaći sve svoje hvalevrijedne rezultate!',
            select_menu: 'Izaberi utrku',
            runner_id: 'Startni broj',
            results: 'Pogledaj rezultate',
            result_error: 'Nismo ništa pronašli, molimo Vas pokušajte ponovno!',
            name: 'Ime',
            last_name: 'Prezime',
            gender: 'Spol',
            start_num: 'Početni broj',
            status: 'Status',
            town: 'Grad',
            club: 'Klub',
            rank: 'Rank',
            time: 'Vrijeme'
        },


    }
}


const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
})


const app = new Vue({i18n}).$mount('#app')


